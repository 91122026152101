.progress-bar {
    width: 90%;
    overflow: hidden;
    margin: 8px auto;
    position: relative;
}

#progress-container {
    flex: 1;
    margin: 2em !important;
}

.progress-bar-info {
    margin-top: 8px;
    color: #999;
}

.progress-bar-title {
    font-size: 16px;
    font-weight: bold;
    padding-left: 10px;
    text-align: left;
}

.progress-bar-details {
    font-size: 14px;
}

.progress-bar-time {
    font-size: 14px;
    padding-left: 10px;
}

.progress-bar-percentage {
    width: 100%;
    height: 20px;
    background-color: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
}

.progress-bar-fill {
    height: 100%;
    background-color: #a7c6f5;
    background: linear-gradient(to right, #a7c6f5, #d0e1fb);
    width: 0;
    transition: width 0.5s ease-in-out;
    border-radius: 5px 0 0 5px;
}