@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700;900&family=Poppins:wght@300;400;500;800&display=swap");

:root {
  --font-family-primary: "Poppins", sans-serif;
  --font-family-secondary: "Nunito", sans-serif;
  --color-light-blue: #a7c6f5;
  --color-dark-blue: #0c1e5c;
  --color-light-gray: #d6d3f5;
  --color-light-gray-cell: #f3f2fa;
  --color-medium-gray: #776f82;
  --color-light-purple: #c7c4e7;
  --color-medium-purple: #a9a5d4;
  --color-dark-purple: #7f7bad;
  --color-dark-gray: #333;
  --color-very-light-gray: #fef8c355;
  --color-light-yellow: #fef8c355;
  --color-light-blue-text: #558ebc;
  --color-light-gray-text: #888;
  --color-light-gray-border: #d6d3f5;
  --color-light-background: #fafafa;
  --color-light-border: #ddd;
  --color-medium-blue: #387fcc;
  --color-dark-blue-hover: #0056b3;
  --color-action: #0056b3;
  --color-light-green: #889c77;
  --color-light-red: #bf8484;
  --color-dark-red: #8b3e47;
  --color-dark-red-hover: #540d15;
  --color-black: #000;
  --color-white: #fff;
  --border-radius-small: 3px;
  --border-radius-medium: 4px;
  --border-radius-large: 8px;
  --border-radius-xlarge: 10px;
}

body {
  font-family: var(--font-family-primary);
  background-color: var(--color-light-blue);
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
}

q {
  font-weight: 200;
  font-style: italic;
  color: #555;
  background-color: var(--color-light-yellow);
  padding: 2px 4px;
  border-radius: var(--border-radius-small);
}

h2 {
  font-family: var(--font-family-secondary);
  font-size: 1.4rem;
  color: var(--color-dark-blue);
  margin-bottom: 10px;
  text-align: left;
}

h3 {
  font-family: var(--font-family-secondary);
  font-size: 1.1rem;
  font-weight: 400;
  color: var(--color-dark-blue);
  margin-top: 8px;
  margin-bottom: 4px;
  text-align: left;
}

h4 {
  font-family: var(--font-family-secondary);
  font-size: 0.9rem;
  font-weight: 400;
  color: var(--color-dark-blue);
  margin-top: 4px;
  margin-bottom: 2px;
  text-align: left;
}

.login-container {
  background-color: white;
  padding: 40px;
  top: 120px;
  position: relative;
  width: 640px;
  border-radius: var(--border-radius-xlarge);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title-login {
  margin-top: 20px;
  font-size: 1.4em;
  color: var(--color-dark-gray);
}

.logo-login {
  width: 140px;
  display: block;
}

.main-input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid var(--color-light-gray);
  border-radius: var(--border-radius-large);
  font-size: 1rem;
  text-align: center;
}

.main-input::placeholder {
  color: var(--color-light-gray);
}

.center-button {
  width: 80%;
  padding: 10px;
  margin: 10px 0;
  border: none;
  border-radius: var(--border-radius-medium);
  font-size: 1rem;
  cursor: pointer;
  color: white;
}

.center-button.primary {
  background-color: var(--color-dark-blue);
}

.center-button.secondary {
  background-color: var(--color-medium-gray);
}

.center-button:hover {
  opacity: 0.7;
}

.footer {
  margin-top: 20px;
  text-align: center;
  font-size: 0.8em;
  color: var(--color-light-gray-text);
}

.page-container {
  position: absolute;
  top: 0;
  left: 200px;
  padding: 20px;
  margin: 0;
  margin-top: 60px;
  width: calc(100% - 200px);
  overflow-x: hidden;
  box-sizing: border-box;
  background-color: #fff;
}

.subtitle {
  font-family: var(--font-family-secondary);
  font-size: 16px;
  color: var(--color-dark-blue);
  margin-bottom: 20px;
  text-align: left;
}

.tasks {
  font-family: var(--font-family-primary);
  margin-top: 120px;
  font-size: 12px;
  color: #aaa;
  margin-bottom: 20px;
  text-align: left;
}

.large-quote-container {
  width: 85%;
  font-size: smaller;
}

.programmer-note {
  font-size: smaller;
  color: var(--color-light-blue-text);
  width: 60%;
}

.user-message {
  font-size: small;
  color: var(--color-light-blue-text);
  width: 60%;
}

.error-message {
  color: var(--color-dark-red-hover);
  width: 80%;
}

.status-text {
  font-size: smaller;
  color: var(--color-light-blue-text);
}

/* Header */

.page-title {
  font-family: var(--font-family-secondary);
  margin-top: 20px;
  font-size: 1.4rem;
  color: var(--color-dark-blue);
  margin-bottom: 20px;
  text-align: left;
}

.header-container {
  position: fixed;
  top: 0;
  background-color: white;
  z-index: 100;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 10px 0px;
  width: calc(100% - 240px);
  height: 80px;
}

.header-container .page-title {
  flex-grow: 1;
  text-align: left;
}

.header-container button {
  margin-right: 10px;
}

.patient-header-container {
  top: 0;
  background-color: white;
  padding: 6px 0;
}

.back-button {
  background-color: var(--color-light-blue);
  border-radius: var(--border-radius-small);
  color: var(--color-dark-blue);
  padding: 4px 8px;
  border: 1px solid var(--color-dark-blue);
}

.header-button {
  display: inline-flex;
  align-items: center;
  padding: 4px 8px;
  margin: 0px 8px;
  background-color: var(--color-light-background);
  color: var(--color-dark-blue);
  border: 1px solid var(--color-light-gray);
  border-radius: var(--border-radius-large);
  cursor: pointer;
  text-decoration: none;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.header-button:hover {
  background-color: var(--color-medium-blue);
  color: white;
}

.header-icon {
  margin: 0px 4px;
  width: 12px;
  height: 12px;
  color: var(--color-dark-purple);
}

.documents-list-container {
  width: 100%;
  padding: 10px;
  flex: 2;
  margin-right: 20px;
  margin-top: 40px;
}

.documents-list-table {
  margin-top: 30px;
}

.header-search {
  align-items: flex-end;
  background-color: white;
  z-index: 100;
}

.header-search-wrapper {
  position: fixed;
  width: calc(100% - 240px);
  display: flex;
  align-items: center;
  z-index: 100;
}

.home-header-search-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.header-search-icon {
  position: absolute;
  left: 6px;
  pointer-events: none;
  color: var(--color-dark-purple);
}

.header-search-input {
  display: inline-flex;
  align-items: center;
  padding: 6px 0px;
  padding-left: 60px;
  margin: 0px;
  border: 1px solid var(--color-light-gray);
  border-radius: var(--border-radius-large);
  font-size: 1rem;
  text-align: left;
  width: 98%;
}

/* editable sections */

.edit-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.edit-header-left {
  display: flex;
  align-items: center;
}

.edit-header-right {
  display: flex-end;
  align-items: center;
}

.edit-header {
  display: flex;
  align-items: center;
  margin: 0;
  margin-left: 10px;
  padding-top: 6px;
}

.edit-header span {
  cursor: pointer;
}

.copy-icon {
  cursor: pointer;
  margin: 0 10px;
  vertical-align: middle;
  color: var(--color-medium-gray);
}

.status-text {
  margin-left: 10px;
}

.accordion-icon {
  vertical-align: middle;
  color: var(--color-medium-gray);
  margin-left: -1rem;
}

/* standard elements */

.standard-container {
  min-height: 40px;
  max-height: 90%;
  width: 100%;
  overflow-y: hidden;
  margin: 0 0;
  margin-bottom: 0.8rem;
}

.content-container {
  width: 100%;
  background-color: var(--color-light-background);
  padding: 1rem 0.6rem;
  border: 1px solid var(--color-light-gray);
  border-radius: var(--border-radius-medium);
}

.patient-content-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 40px;
}

.patient-details-container {
  flex: 1;
  width: 80%;
  padding: 10px;
  margin-top: 60px;
}

.patient-details {
  color: var(--color-dark-gray);
  font-size: 0.9rem;
  line-height: 1.4;
  font-weight: 300;
}

.patient-details strong {
  font-weight: 500;
}

.patient-details-small {
  color: var(--color-dark-gray);
  font-size: 0.8rem;
  line-height: 1.4;
  font-weight: 400;
}

.patient-list {
  margin-bottom: 1em !important;
}


/* replace with a standard form */

.add-patient-form {
  margin-top: 20px;
}

.add-patient-form input {
  margin-right: 10px;
  padding: 5px;
  border: 1px solid var(--color-light-border);
  border-radius: var(--border-radius-small);
}

.add-patient-form button {
  padding: 5px 10px;
  border: none;
  background-color: var(--color-light-blue);
  color: white;
  cursor: pointer;
  border-radius: var(--border-radius-small);
}

.add-patient-form button:hover {
  background-color: var(--color-dark-blue-hover);
}

/* Standard Table */

.table-container {
  overflow-x: auto;
}

.standard-table {
  width: 100%;
  table-layout: fixed;
  border-bottom: 1px solid #ddd;
  border-collapse: collapse;
}

.standard-table.wide {
  width: 130%;
  min-width: 100%;
}

.standard-table.thinner {
  width: 100%;
  min-width: 96%;
}

.standard-table.narrow {
  width: 60%;
  min-width: 50%;
}

.standard-table th {
  font-weight: 400;
  font-size: 0.6rem;
  text-align: left;
  padding-left: 0.3rem;
}

.standard-table td {
  vertical-align: top;
}

.standard-table-header-small {
  font-size: 0.7em;
  font-weight: 200;
}

.standard-table td {
  padding: 0px 2px;
}

.standard-table th {
  background-color: var(--color-light-background);
}

.table-checkbox {
  width: 0.9rem;
  height: 0.9rem;
  margin-top: 0.2rem;
  background-color: 2px solid var(--color-light-background);
  accent-color: var(--color-medium-purple);
}

.pagination-controls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 1em;
  font-size: small;
}

.pagination-controls span {
  margin: 0 10px;
}

.table-primary-text {
  color: var(--color-dark-blue);
  font-weight: 400;
  font-size: 1rem;
}

.table-secondary-text {
  color: var(--color-light-gray-text);
  font-weight: 400;
  font-size: 0.9rem;
  padding-left: 8px;
}

.table-icon {
  position: relative;
  top: 4px;
  padding: 0;
  color: var(--color-medium-purple);
}

.green-icon {
  color: var(--color-light-green);
}

.red-icon {
  color: var(--color-light-red);
}

.table-icon-lighter {
  top: 2px;
  position: relative;
  color: var(--color-light-gray-text);
}

.standard-input-cell {
  padding: 6px;
  border: 1px solid var(--color-light-border);
  border-radius: var(--border-radius-small);
  width: 100%;
  box-sizing: border-box;
  font-family: var(--font-family-secondary);
  font-size: 1.1em;
  color: var(--color-dark-blue);
}

.standard-input-cell:focus {
  border: 1px solid var(--color-dark-blue);
  outline: none;
}

.standard-input {
  width: 80%;
  box-sizing: border-box;
  padding: 8px;
  font-size: 16px;
}

.standard-radio {
  width: 90%;
  margin-left: 12px;
  box-sizing: border-box;
  padding: 8px;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.standard-radio input[type="radio"] {
  accent-color: var(--color-light-blue);
  width: 20px;
  height: 20px;
  cursor: pointer;
  flex-shrink: 0;
  margin: 0;
  padding: 0;
}

.standard-radio-text {
  font-size: 16px;
  color: var(--color-dark-blue-hover);
}

.standard-textarea {
  width: 100%;
  height: auto;
  padding: 0.4rem;
  border: 1px solid var(--color-light-border);
  border-radius: var(--border-radius-small);
  font-family: var(--font-family-secondary);
  color: var(--color-dark-blue);
  font-size: 1.1em;
  resize: vertical;
  overflow: hidden;
}

.standard-textarea::-webkit-scrollbar {
  width: 0;
}

.standard-textarea::-webkit-resizer {
  width: 1px;
  height: 1px;
}

.table-container::-webkit-scrollbar {
  height: 6px;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: var(--color-medium-purple);
  border-radius: 6px;
}

.table-container::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-medium-purple);
}

.table-container {
  scrollbar-width: thin;
  scrollbar-color: var(--color-medium-purple) #f1f1f1;
}

.table-container::-webkit-scrollbar-button {
  display: none;
}

/* Patient History Tabs */

.patient-tabs-container {
  background-color: var(--color-light-background);
  position: fixed;
  z-index: 100;
  width: calc(100% - 240px);
  border-bottom: 2px solid var(--color-light-purple);
}

.patient-history-container {
  flex: 1;
  width: 100%;
  padding: 0px;
  margin-top: 44px;
}

.patient-tabs {
  background-color: var(--color-light-background);
  height: 38px !important;
}

.patient-tab {
  text-transform: none !important;
  padding: 10px 10px !important;
  font-size: 12px !important;
}

.patient-tab.Mui-selected {
  background-color: var(--color-light-purple);
  color: var(--color-dark-blue) !important;
}

.patient-subtabs-container {
  background-color: var(--color-light-background);
  position: fixed;
  z-index: 100;
  width: calc(100% - 240px);
  border-bottom: 1px solid var(--color-light-purple);
}

.patient-sub-tabs {
  background-color: var(--color-light-background);
  height: 32px !important;
}

.patient-sub-tab {
  text-transform: none !important;
  padding: 10px 10px !important;
  font-size: 12px !important;
}

.patient-sub-tab.Mui-selected {
  background-color: var(--color-light-gray);
  color: var(--color-dark-blue) !important;
}

.history-summary-section {
  margin-top: 60px;
  padding: 10px;
}

.history-summary-list {
  color: var(--color-medium-gray);
  font-weight: 300;
  font-size: 14px;
  width: 80%;
  margin-top: 3.4em;
}

.history-summary-list li {
  margin-bottom: 12px;
}

.history-summary-list li::marker {
  font-size: 0.9em;
}

/* tags */

.tag-box {
  display: inline-block;
  background-color: var(--color-light-gray);
  color: var(--color-medium-gray);
  border-radius: 3px;
  padding: 2px 6px;
  margin-left: 8px;
  font-size: 0.66rem;
}

/* Column spacing */

.column-100 {
  width: 100%;
}

.column-90 {
  width: 90%;
}

.column-80 {
  width: 80%;
}

.column-70 {
  width: 70%;
}

.column-60 {
  width: 60%;
}

.column-30 {
  width: 30%;
}

.column-20 {
  width: 20%;
}

.column-15 {
  width: 15%;
}

.column-10 {
  width: 10%;
}

.column-7 {
  width: 7%;
  font-size: 0.8rem;
}

.column-5 {
  width: 5%;
  font-size: 0.8rem;
}

.column-3 {
  width: 3%;
  font-size: 0.4rem;
}

.column-right {
  text-align: right;
  vertical-align: top;
  font-weight: 400;
  padding-top: 12px !important;
}

.column-first {
  background-color: white;
  box-shadow: inset 0 0px 0 1px var(--color-light-background);
  font-size: 0.9rem;
  font-weight: 400 !important;
  line-height: 1.2rem;
  border-radius: 0.4rem 0 0 0.4rem;
}

.column-gray {
  background-color: var(--color-light-gray-cell);
  padding: 0.8rem !important;
}

.column-history {
  /* border: 1px dashed var(--color-light-border); */
  color: var(--color-light-blue-text);
}

/* Tabs */

.tabs-container {
  max-height: 400px;
  width: 90%;
  overflow-y: auto;
}

.tabs {
  display: flex;
  margin-top: 40px;
}

.tabs button {
  padding: 10px 20px;
  cursor: pointer;
  border: 1px solid var(--color-light-gray-border);
  background-color: var(--color-light-background);
  margin-right: 5px;
  border-radius: var(--border-radius-medium) var(--border-radius-medium) 0 0;
}

.tabs button.active {
  background-color: white;
  border-bottom: none;
  font-weight: bold;
}

.tab-content {
  border: 1px solid var(--color-light-gray-border);
  padding: 20px;
  border-radius: 0 var(--border-radius-medium) var(--border-radius-medium) var(--border-radius-medium);
  background-color: white;
}

/* Buttons */

.button-edit,
.button-update,
.button-delete,
.button-add,
.button-cancel,
.button-action,
.button-continue {
  padding: 0.4em 1em;
  margin: 0px 8px;
  font-size: 1em;
  font-weight: 400;
  border: none;
  border-radius: var(--border-radius-small);
  cursor: pointer;
}

.button-larger {
  padding: 0.6em 2em;
  margin: 0px 8px;
  font-size: 1.2em;
  font-weight: 400;
}

.button-edit {
  background-color: var(--color-medium-blue);
  color: white;
}

.button-cancel {
  background-color: var(--color-light-gray-text);
  color: white;
  font-size: 1.4em;
}

.button-update {
  background-color: var(--color-light-blue-text);
  color: white;
}

.button-delete {
  background-color: var(--color-dark-red);
  color: white;
  font-size: 1.4em;
}

.button-delete:hover {
  background-color: var(--color-dark-red-hover);
  color: white;
  font-size: 1.4em;
}

.button-action {
  background-color: var(--color-action);
  color: white;
  font-size: 1.4em;
}

.button-continue {
  background-color: var(--color-light-blue-text);
  color: white;
  font-size: 1.4em;
}

.button-continue:disabled {
  background-color: grey;
  cursor: not-allowed;
}

.button-add {
  background-color: var(--color-medium-blue);
  color: white;
}

/* transcript */

.upload-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  margin-top: 10px;
}

.transcript {
  font-size: 0.8em;
  white-space: pre-wrap;
}

.transcript-html {
  font-size: 0.82em;
  line-height: 1.5;
  font-weight: 300;
  width: 90%;
}

.transcript-html q {
  font-weight: 200;
  font-style: italic;
  background-color: var(--color-light-background);
  padding: 2px 4px;
  border-radius: var(--border-radius-small);
}

.transcript-box {
  height: 280px;
  max-height: 90%;
  width: 100%;
  overflow-y: auto;
  border: 1px solid var(--color-light-gray-border);
  padding: 10px;
  overflow-x: hidden;
  background-color: var(--color-light-background);
}

.transcript-box-short {
  max-height: 120px;
  width: 64%;
  overflow-y: auto;
  border: 1px solid var(--color-light-gray-border);
  padding: 10px;
  overflow-x: hidden;
  background-color: var(--color-light-background);
}

.transcript-box::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.transcript-box::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 10px;
}

.transcript-box::-webkit-scrollbar-track {
  background: var(--color-light-background);
}

.summary {
  width: 600px;
  font-size: 10px;
  white-space: pre-wrap;
}

.summary-small {
  font-size: 0.8em;
  line-height: 1.2;
  font-weight: 400;
  padding-bottom: 0.6rem;
  width: 90%;
}

.summary-overview {
  font-size: 0.8em;
  line-height: 1.2;
  font-weight: 300;
  padding-bottom: 1rem;
  width: 90%;
}

.menu-container {
  padding-left: 0px;
  position: relative;
  top: -4px;
  left: -8px;
}

/* document page */

.document-details {
  font-family: var(--font-family-secondary);
  color: var(--color-dark-blue);
  margin-bottom: 6px;
  text-align: left;
  font-weight: 400;
}

.document-title {
  font-size: 1.4rem;
  font-weight: 600;
  min-width: 400px;
  align-items: center;
  justify-content: center;
  padding: 2px 6px;
  position: relative;
  border-radius: var(--border-radius-small);
  border: 1px solid var(--color-light-gray-border);
  width: 100%;
  background-color: var(--color-white);
  font-family: var(--font-family-secondary);
  font-weight: 600;
}

.document-title-input {
  color: var(--color-dark-blue);
  background-color: var(--color-light-gray);
  font-size: 1.4rem;
  width: 100%;
  border: none;
  font-family: var(--font-family-secondary);
  font-weight: 600;
  outline: none;
}

.document-patient {
  margin-top: 2em;
  font-weight: 200;
  font-size: 1.4rem;
}

.document-date {
  font-style: normal;
  font-weight: 300;
  font-size: 1em;
  padding: 2px 6px;
  position: relative;
  margin: 8px 10px 4px 0px;
  width: 180px;
  border-radius: var(--border-radius-small);
  background-color: var(--color-white);
  border: 1px solid var(--color-light-gray-border);
}

.document-date-input {
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  outline: none;
  border: 0px;
  box-sizing: border-box;
  width: 100px;
}

.document-date small {
  font-size: 0.6em;
  color: var(--color-light-gray-text);
  margin-right: 5px;
  vertical-align: super;
}

.document-time-input {
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  outline: none;
  border: 0px;
  box-sizing: border-box;
  width: 40px;
}

.document-therapy {
  font-style: normal;
  font-weight: 300;
  font-size: 1em;
  padding: 2px 6px;
  position: relative;
  margin: 8px 10px 4px 0px;
  width: 220px;
  border-radius: var(--border-radius-small);
  background-color: var(--color-white);
  border: 1px solid var(--color-light-gray-border);
}

.document-therapy small {
  font-size: 0.6em;
  color: var(--color-light-gray-text);
  margin-right: 5px;
  vertical-align: super;
}

.document-therapy-input {
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  outline: none;
  border: 0px;
  box-sizing: border-box;
  width: 120px;
}

.document-times {
  display: flex;
  justify-content: space-between;
}

.datetime-header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.document-text {
  font-style: normal;
  font-weight: 300;
  font-size: 0.7em;
}

.document-info {
  display: flex;
  flex-direction: column;
  background-color: var(--color-light-gray);
  width: 100%;
  padding: 10px;
}

.document-span {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  font-style: normal;
  font-weight: 300;
  font-size: 0.6em;
}

/* confirmation & export */

.confirmation-textarea {
  margin-left: 6%;
  margin-top: 2%;
  width: 92%;
  font-size: 14px;
}

.export-container {
  display: flex;
  flex-direction: column;
  margin: 20px;
}

.export-section {
  min-height: 50px;
  height: auto;
}

.export-text {
  width: 100%;
  max-height: 600px;
  font-size: 14px;
  user-select: text;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  overflow-x: auto;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 4px;
}

.export-button-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.export-button-container button {
  height: 100px;
  width: 200px;
  margin-bottom: 4em;
  font-size: 16px;
  background-color: var(--color-light-blue);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.export-button-container button:hover {
  background-color: var(--color-dark-blue);
  color: var(--color-light-gray);
}

.export-button-container button:last-child {
  margin-bottom: 0;
}

.export-tabs {
  display: flex;
  justify-content: left;
  border-bottom: 1px solid #ccc;
  margin-bottom: 0px;
}

.export-tab {
  padding: 10px 20px;
  margin: 0 5px 0 0; /* Remove bottom margin */
  cursor: pointer;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-bottom: none; /* Remove bottom border */
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  transition: background-color 0.3s ease;
}

.export-tab.active {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
  border-bottom: 1px solid #007bff; /* Add bottom border to active tab */
}

.export-tab:hover {
  background-color: #e0e0e0;
}

/* modal */

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: var(--color-light-background);
  padding: 4em;
  border: 4px solid var(--color-light-gray-text);
  width: 80%;
  max-width: 980px;
  height: auto;
  border-radius: var(--border-radius-large);
  font-size: 0.8em;
  font-weight: 400;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid var(--color-light-gray-border);
  white-space: pre-wrap;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1001;
}

.modal-title {
  font-family: var(--font-family-secondary);
  font-size: 1.4rem;
  color: var(--color-dark-blue);
  margin-bottom: 1.4em;
  margin-left: 0.4em;
  text-align: left;
}

.modal-form {
  font-size: 1rem;
}

.modal-input {
  width: 80%;
  box-sizing: border-box;
  padding: 8px;
  margin: 1em 0;
  font-size: 1rem;
  border: 1px solid var(--color-light-border);
  border-radius: var(--border-radius-small);
}

.modal-radio {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
  margin: 1em 0;
}

.modal-radio label {
  display: flex;
  align-items: center;
  margin-right: 1em;
  cursor: pointer;
}

.modal-radio input[type="radio"] {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid var(--color-dark-blue-hover);
  border-radius: 50%;
  margin-right: 10px;
  outline: none;
  cursor: pointer;
  transition: background 0.4s, border-color 0.4s;
}

.modal-radio input[type="radio"]:checked {
  background-color: var(--color-dark-blue-hover);
  border-color: var(--color-dark-blue-hover);
}

.modal-error-message {
  color: var(--color-dark-red-hover);
  margin: 0 0 1em 0;
  width: 80%;
}

.modal-dropdown {
  width: 100%;
  box-sizing: border-box;
  padding: 8px;
  margin: 1em 0;
}

.modal-select {
  box-sizing: border-box;
  padding: 8px;
  margin: 1em 0;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  width: 100%;
}

.modal-button-container {
  display: flex;
  justify-content: flex-end;
  margin-right: 1em;
}

.close {
  color: white;
  float: right;
  font-size: 1.4em;
  font-weight: bold;
  position: fixed;
  top: 9%;
  right: 9%;
  border-radius: 50%;
  width: 1.4em;
  height: 1.4em;
  line-height: 1.4em;
  cursor: pointer;
  background-color: var(--color-light-blue-text);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2001;
}

.close:hover,
.close:focus {
  color: var(--color-black);
  text-decoration: none;
}

/* chart */

.chart-tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.chart-tooltip .chart-tooltiptext {
  visibility: hidden;
  width: 240px;
  background-color: var(--color-black);
  color: var(--color-white);
  text-align: center;
  border-radius: var(--border-radius-medium);
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  font-size: 16px;
}

.chart-tooltip:hover .chart-tooltiptext {
  visibility: visible;
}

/* quill customization */

.ql-container.ql-snow {
  border-radius: var(--border-radius-medium);
  border-color: var(--color-light-border) !important;
}

.text-editor-box {
  background-color: var(--color-white);
}

.text-history {
  color: var(--color-light-blue-text);
}

.editor-message {
  font-size: 1em;
  font-style: italic;
  color: var(--color-light-blue-text);
  padding: 0.2em;
  resize: none;
}

.ql-editor p {
  margin-bottom: 16px !important;
}

.ql-editor p:last-child {
  margin-bottom: 0 !important;
}

/* Session Streaming */

.session-header-container {
  display: flex;
  flex-direction: row;
  align-items: left;
  position: fixed;
  background-color: var(--color-light-gray-cell);
  width: calc(100% - 240px);
  height: 180px;
  padding: 1.4em;
  border-radius: var(--border-radius-medium);
  border: 2px solid var(--color-light-gray-border);
  z-index: 10;
  gap: 1rem;
}

.session-streaming {
  border: 4px solid var(--color-light-red);
}

.session-processing {
  border: 4px solid var(--color-dark-purple);
}

.session-processing {
  border: 4px solid var(--color-dark-blue);
}

.session-recording-container {
  display: flex;
  align-items: flex-start;
  width: 50%;
  gap: 1rem;
}

.streaming-container {
  width: 50%;
}

.session-progress-container {
  position: fixed;
  width: calc(96% - 240px);
  margin: 1em;
  text-align: center;
  top: 100px;
}

.session-header-spacing {
  height: 80px;
  width: calc(100% - 240px);
  background-color: white;
  position: fixed;
  margin-top: 170px;
  padding: 8px;
  padding-top: 36px;
  font-size: 1em;
  z-index: 9;
}

.session-history-spacing {
  position: relative;
  top: -20px;
  overflow-y: hidden;
}


.session-subtabs-container {
  margin-top: 240px;
}

.recording-button {
  padding: 10px 20px;
  font-size: 16px;
  color: var(--color-white);
  width: 360px;
  background-color: var(--color-medium-blue);
  border: none;
  border-radius: var(--border-radius-medium);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.recording-button:hover {
  background-color: var(--color-dark-blue-hover);
}



.streaming-controls {
  display: flex;
  align-items: center;
}

.cancel-button {
  padding: 10px 20px;
  margin-top: 20px;
  font-size: 16px;
  color: var(--color-white);
  width: 160px;
  background-color: var(--color-dark-red);
  border: none;
  border-radius: var(--border-radius-medium);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cancel-button:hover {
  background-color: var(--color-dark-red-hover);
}

.recording-status {
  margin-left: 1em;
  margin-top: 16px;
  font-size: 16px;
  color: var(--color-dark-gray);
}

.audio-upload-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.audio-upload-label {
  padding: 10px 20px;
  font-size: 16px;
  color: var(--color-white);
  background-color: var(--color-medium-blue);
  border: none;
  border-radius: var(--border-radius-medium);
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: inline-block;
}

.audio-upload-label:hover {
  background-color: var(--color-dark-blue-hover);
}

.audio-upload-label input[type="file"] {
  display: none;
}

.volume-meter {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  width: 360px;
  height: 20px;
  background-color: var(--color-light-background);
  border: 1px solid var(--color-light-gray-border);
  padding: 5px;
  box-sizing: border-box;
}

.volume-bar {
  width: 10%;
  height: 100%;
  background: linear-gradient(to right, var(--color-light-blue), var(--color-light-blue));
  margin: 2px 2px;
  display: none;
  border-radius: var(--border-radius-small);
}

/* dropdowns */

.dropdown-container {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
  width: 100%;
}

.justify-right {
  justify-content: flex-end;
  padding-right: 0.4rem;
}

.dropdown-container label {
  margin-bottom: 0.5rem;
  margin-right: 1em;
  font-size: 1em;
}

.dropdown-container select {
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid var(--color-light-gray-border);
  border-radius: var(--border-radius-medium);
  width: 100%;
  max-width: 300px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* drag and drop */

.drop-zone {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  margin: 20px 0;
}

.drop-zone.dragging {
  border-color: #000;
}

.drop-zone.dropped {
  border: 4px dashed var(--color-dark-blue-hover);
}