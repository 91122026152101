
:root {
    --font-family-primary: "Poppins", sans-serif;
    --font-family-secondary: "Nunito", sans-serif;
    --color-light-blue: #a7c6f5;
    --color-dark-blue: #0c1e5c;
    --color-medium-gray: #776f82;
    --color-dark-purple: #7f7bad;
    --color-dark-gray: #333;
    --color-light-blue-text: #558ebc;
    --color-light-gray-text: #888;
    --color-light-gray-border: #d6d3f5;
    --color-light-background: #F0F2F5;
    --color-medium-blue: #387fcc;
    --color-black: #000;
    --color-white: #fff;
  }

.sidebar {
    width: 200px;
    height: 100vh;
    background-color: var(--color-light-background);
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 20px;
    padding-left: 22px;
    font-family: var(--font-family-primary);
}

.sidebar ul {
    list-style-type: none;
    padding: 0;
}

.sidebar ul li {
    margin: 40px 0;

}

.sidebar ul li a {
    color: var(--color-dark-blue);
    text-decoration: none;
    font-size: 16px;
    font-weight: 200;
}

.sidebar ul li a:hover {
    color: var(--color-dark-purple);
}

.logo-sidebar {
    width: 66%;
    display: block;
    margin-left: -20px;
    margin-top: 24px;
}

.sidebar ul li.active a {
    font-weight: 500;
    color: var(--color-medium-blue);
    background-color:var(--color-light-gray-border);
    text-decoration: none;
    padding: 4px 80px 4px 4px;
}

.sidebar-footer {
    margin-top: 60px;
    width: 80%;
    text-align: left;
    font-size: 0.6em;
    color: var(--color-dark-gray);
}

.user-info {
    font-weight: 200;
    color:var(--color-dark-purple);
    font-size: 0.7em;
    text-decoration: none;
    margin-top: 40px;
    margin-bottom: 80px;
    width: 89%
}